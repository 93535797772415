<template>
  <div v-if="documents">
    <div class="px-8 py-6 bg-white">
      <h1
        class="text-4xl font-bold leading-snug text-left responsive-container"
      >
        {{ $t('user.searchResult.title') }}
      </h1>
    </div>
    <div class="flex items-center responsive-container mx-8 mt-10 4xl:mx-auto">
      <user-document-filter
        ref="userDocumentFilterOverlay"
        class="mr-8"
        @filterResults="filterResults"
      />
      <div class="bg-secondary-800 bg-opacity-[15%] p-3 rounded flex">
        <span class="font-sansbold mr-2">
          {{ $t('user.allContent.hint') }}
        </span>
        <span>{{ $t('user.allContent.hintText') }}</span>
      </div>
    </div>

    <div class="relative responsive-container">
      <div id="loading" />
      <search-result-tabs
        class="mx-8 responsive-container mt-10 4xl:mx-auto"
        :lang="currentLanguage"
        :documents="documents"
        :active-language="currentLanguage"
        @changeLanguageTab="setCurrentLanguage"
      />
      <div class="h-full mb-16">
        <div class="py-12 bg-white shadow-md px-9 responsive-container">
          <div>
            <button
              class="btn mr-3"
              :class="getSortButtonClass(true)"
              @click="orderAsc"
            >
              {{ $t('user.allContent.sortAZ') }}
            </button>
            <button
              class="btn"
              :class="getSortButtonClass(false)"
              @click="orderDesc"
            >
              {{ $t('user.allContent.sortZA') }}
            </button>
          </div>
          <user-selected-filter
            :selected-filters="selectedFilters"
            @deleteFilter="deleteFilter"
          />

          <div class="mt-4">
            <div class="font-sansbold pb-3 border-b border-gray-800">
              <div class="w-[150px] inline-block">
                {{ $t('user.allContent.rootValidFrom') }}
              </div>
              <div class="inline-block">
                {{ $t('user.allContent.document') }}
              </div>
            </div>
            <div
              v-for="document in results"
              :key="document.id"
              class="flex mt-3"
            >
              <div class="w-[150px]">
                <span>{{ getRootValidFrom(document) }}</span>
              </div>
              <div class="w-1/2">
                <router-link
                  class="document--link"
                  :to="getDocumentRoute(document)"
                >
                  {{ getTitle(document) }}
                  {{ isFileOnlyDocument(document) ? '[PDF]' : '' }}
                </router-link>
                <div class="flex items-center">
                  <p class="text-lg text-gray-800">
                    <search-document-state :state="document.validityState" /> |
                  </p>

                  <document-information :document="document" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LoadingSpinner
      v-if="isLoading[currentLanguage]"
      :importing="false"
      mount-to="#loading"
    />
  </div>
</template>

<script>
import UserSelectedFilter from '@/components/user/searchResult/UserSelectedFilter';
import UserEventBus from '@/helpers/UserEventBus';
import { getAllContent } from '@/services/document';
import SearchResultTabs from '@/components/user/searchResult/SearchResultTabs.vue';
import { mapState } from 'vuex';
import { cleanFilters } from '@/helpers/filter';
import UserDocumentFilter from '@/components/user/searchResult/UserDocumentFilter.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import DocumentInformation from '@/components/user/searchResult/DocumentInformation';
import SearchDocumentState from '@/components/SearchDocumentState';
import { formatDate } from '@/helpers/DateFormatters';

export default {
  name: 'AllContentList',
  components: {
    UserSelectedFilter,
    SearchResultTabs,
    UserDocumentFilter,
    LoadingSpinner,
    DocumentInformation,
    SearchDocumentState,
  },
  data() {
    return {
      documents: {
        de: { count: 0, results: [] },
        fr: { count: 0, results: [] },
        it: { count: 0, results: [] },
        en: { count: 0, results: [] },
      },
      searchTerm: '',
      currentPage: 1,
      currentLanguage: this.$i18n.locale,
      filters: {},
      order: 'title',
      isLoading: { de: false, fr: false, it: false, en: false },
    };
  },
  computed: {
    ...mapState('Search', ['isExactSearch', 'selectedFilters']),
    hasNoResults() {
      return this.documents[this.currentLanguage]?.results.length <= 0;
    },
    results() {
      return this.documents[this.currentLanguage]?.results || [];
    },
    count() {
      return this.documents[this.currentLanguage]?.count;
    },
  },
  async mounted() {
    this.$store.dispatch('Search/loadAvailableFilters');
    this.$store.commit('Search/setSelectedFilters', {
      state: [
        {
          id: 'is_in_order',
          name: this.$t('document.states.inOrder'),
          type: 'state',
        },
      ],
    });

    this.searchResults();
  },
  methods: {
    deleteFilter(filter, filterKey) {
      UserEventBus.$emit('deleteFilter', filter, filterKey);
    },
    setSelectedFilters(selectedFilters) {
      this.$store.commit('Search/setSelectedFilters', selectedFilters);
    },
    async searchResults() {
      const languages = ['de', 'fr', 'it', 'en'];

      const loadDocuments = (lang) => {
        this.isLoading[lang] = true;
        const promise = getAllContent({
          ...this.replaceStateFilter(cleanFilters(this.selectedFilters)),
          lang: lang,
          no_pagination: true,
          all_content: true,
          document_lang: lang,
          only_published: true,
          ordering: this.order,
        });
        promise.then((response) => {
          this.$set(this.documents, lang, {
            count: response.length,
            results: response,
          });
          this.isLoading[lang] = false;
        });

        return promise;
      };

      // We first want to load the language that the user is seeing. After that the other languages are loaded in the background
      loadDocuments(this.currentLanguage).then(() => {
        languages.forEach((lang) => {
          if (lang !== this.currentLanguage) {
            loadDocuments(lang);
          }
        });
      });
    },
    setCurrentLanguage(lang) {
      this.currentLanguage = lang;
    },
    filterResults(filters, selectedFilters) {
      this.filters = filters;
      this.selectedFilters = selectedFilters;
      this.searchResults();
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    replaceStateFilter(filters) {
      if (filters.state) {
        filters.validity_state = filters.state;
        delete filters.state;
      }
      return filters;
    },
    orderAsc() {
      this.order = 'title';
      this.searchResults();
    },
    orderDesc() {
      this.order = '-title';
      this.searchResults();
    },
    getSortButtonClass(asc) {
      if (asc) {
        return this.order === 'title' ? ['btn--primary'] : ['btn--secondary'];
      }
      return this.order === '-title' ? ['btn--primary'] : ['btn--secondary'];
    },
    isFileOnlyDocument(document) {
      return document.importType === 'file_only';
    },
    getDocumentRoute(document) {
      const route = {
        name: 'documentDetail',
        params: {
          id: document.id,
        },
        query: { lang: this.currentLanguage, q: this.searchTerm },
      };
      return route;
    },
    getTitle(document) {
      return document[
        'title' +
          this.currentLanguage.charAt(0).toUpperCase() +
          this.currentLanguage.slice(1)
      ];
    },
    getRootValidFrom(document) {
      return formatDate(document.rootValidFrom);
    },
  },
};
</script>

<style>
#loading .loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 14rem;
  z-index: 50;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
