<template>
  <mounting-portal append :mount-to="mountTo">
    <div class="loading">
      <div>
        <div class="icon">
          <svg-icon
            class="inline-block w-3 svg-icon svg-icon--chevron-left"
            name="tools-hammer-2"
          />
        </div>
        <div v-if="importing" class="mt-16 text">
          {{ $t('admin.loading.message') }}
          <router-link to="/admin" class="mt-4 block text-white">
            {{ $t('admin.loading.backToOverview') }}
          </router-link>
        </div>
      </div>
    </div>
  </mounting-portal>
</template>
<script>
export default {
  name: 'LoadingSpinner',
  props: {
    importing: {
      type: Boolean,
      required: false,
      default: false,
    },
    mountTo: {
      type: String,
      default: 'body',
    },
  },
};
</script>
