<template>
  <document-layout class="frontend">
    <all-content-list />
  </document-layout>
</template>

<script>
import AllContentList from '@/components/user/AllContentList.vue';

export default {
  name: 'AllContent',
  components: {
    AllContentList,
  },
  mounted() {
    document.body.classList.add('frontend');
    document.body.classList.remove('backend');
  },
};
</script>

<style scoped></style>
